export const DATA_VSWITCH = 'DATA_VSWITCH';
export const UUID = 'UUID';
export const EDGE = 'EDGE';
export const NODES = 'NODES';
export const LOADING_FLOW = 'LOADING_FLOW';
export const UPDATE_FLOW = 'UPDATE_FLOW';
export const OPEN_GRAPH = 'OPEN_GRAPH';
export const OPEN_DRAWER_NETWORK = 'OPEN_DRAWER_NETWORK';
export const DATA_LOCATION = 'DATA_LOCATION';
export const RESERVED_IP = 'RESERVED_IP';
export const TAB_NETWORK = 'TAB_NETWORK';
