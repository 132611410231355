import { useEffect } from 'react';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import AuthService from 'service/auth.service';

export const ComponentWithEffect = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        const regisData = localStorage.getItem('regis');
        const check_regis = localStorage.getItem('check_regis');
        try {
            const regisParsed = JSON.parse(regisData);

            if (regisParsed === false) {
                if (check_regis === 'invitation_regis') {
                    navigate('/postpaid/registration');
                } else {
                    navigate('/complete-registration');
                }
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        dispatch({ type: 'CREDS_FETCH', payload: false });
        dispatch({ type: 'ACCESS', payload: false });
        if (location.pathname !== '/billing') {
            dispatch({ type: 'PAYMENT_METHOD', payload: [] });
            dispatch({ type: 'DATA_INVOICE', payload: '' });
        }

        const checkAuthentication = async () => {
            try {
                if (localStorage.getItem('user_info')) {
                    // Delay for 5 seconds if user variable is not empty
                    const res = await AuthService.checkAuth();
                    dispatch({ type: 'USER_INFO', payload: res.data });
                    localStorage.setItem('user_info', JSON.stringify(res.data));
                    dispatch({ type: 'CREDS_FETCH', payload: true });
                    dispatch({ type: 'TOTAL_UNREAD', payload: res?.data?.total_notifications });
                    dispatch({ type: 'ALL_NOTIFICATION', payload: res?.data?.partial_notifications });
                    dispatch({ type: 'ACCESS', payload: true });
                }
            } catch (error) {
                dispatch({ type: 'ACCESS', payload: false });
            }
        };

        checkAuthentication();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dispatch]);
};
