import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const AlertHeader = ({ user, leftDrawerOpened, alertHeader }) => {
    const navigate = useNavigate();

    if (!alertHeader) return null;

    const accountMessage =
        user?.userinfo?.balance < 0 ? 'Your account is suspended. Please Top Up' : 'Your account is suspended. Please contact our support';

    const buttonText = user?.userinfo?.balance < 0 ? 'Top Up' : 'Our Support';

    const handleClick = () => {
        if (user?.userinfo?.balance < 0) {
            navigate('/billing/top_up');
        } else {
            window.location.href = process.env.REACT_APP_OUR_SUPPORT;
        }
    };

    return (
        <Box sx={{ background: 'rgba(247, 227, 226, 1)' }}>
            <Box
                gap={2}
                display="flex"
                alignItems="center"
                p={0.8}
                sx={{
                    position: 'relative',
                    left: { xs: '20px', lg: leftDrawerOpened ? '280px' : '90px' }
                }}
            >
                <Typography sx={{ color: 'rgba(235, 0, 27, 1)' }}>{accountMessage}</Typography>
                <Button
                    onClick={handleClick}
                    sx={{ minWidth: 'fit-content !important', padding: '3px 20px' }}
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    {buttonText}
                </Button>
            </Box>
        </Box>
    );
};

AlertHeader.propTypes = {
    user: PropTypes.shape({
        userinfo: PropTypes.shape({
            balance: PropTypes.number
        })
    }),
    leftDrawerOpened: PropTypes.bool,
    alertHeader: PropTypes.bool,
    onClick: PropTypes.func
};

export default AlertHeader;
