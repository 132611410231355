import api from './api';

const country = () => {
    return api.get('/lookup/country');
};

const province = () => {
    return api.get('/lookup/province');
};

const city = (province_id) => {
    return api.get(`/lookup/city?province_id=${province_id}`);
};

const district = (city_id) => {
    return api.get(`/lookup/district?city_id=${city_id}`);
};

const getAppTNC = () => {
    return api.get(`/lookup/tnc`);
};

const passwordStrength = () => {
    return api.get(`/lookup/pass`);
};

const LookupService = {
    country,
    province,
    city,
    district,
    getAppTNC,
    passwordStrength
};

export default LookupService;
