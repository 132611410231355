import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItemLink = forwardRef((props, ref) => <Link ref={ref} {...props} />);
NavItemLink.displayName = 'NavItemLink';

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    const { pathname } = location;

    const customization = useSelector((state) => state.customization);

    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const billingSystem = JSON.parse(localStorage.getItem('user_info'))?.userinfo?.billing;

    const itemTarget = item.target ? '_blank' : '_self';

    const listItemProps = item?.external
        ? { component: 'a', href: item.url, target: itemTarget }
        : { component: NavItemLink, to: item.url, target: itemTarget };

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }

        // eslint-disable-next-line
    }, []);

    const isItemOpen = customization.isOpen.findIndex((id) => id === item.id) > -1;

    const getListItemStyles = () => {
        const listItemStyles = {
            borderRadius: `${customization.borderRadius}px`,
            mb: 0.5,
            alignItems: 'flex-start',
            py: level > 1 ? 1 : 1.25,
            pl: `${level * 24}px`,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            marginBottom: '10px',
            display: item.billing === billingSystem ? 'none' : ''
        };

        if (level > 1) {
            listItemStyles.backgroundColor = 'transparent !important';
        } else {
            listItemStyles.backgroundColor = 'inherit';
        }

        return listItemStyles;
    };

    const getItemIcon = () => {
        return (
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
                <img
                    style={{
                        position: item.id === 'overview' ? 'relative' : '',
                        left: item.id === 'overview' ? '3px' : ''
                    }}
                    src={isItemOpen ? item.iconActive : item.icon}
                    alt=""
                />
            </ListItemIcon>
        );
    };

    const getItemText = () => {
        return (
            <ListItemText
                sx={{ opacity: (level > 1 && !leftDrawerOpened) || leftDrawerOpened ? 1 : 0 }}
                primary={
                    <Typography variant={isItemOpen ? 'h5' : 'body1'} color={isItemOpen ? 'primary' : '#959595'}>
                        {item.id === 'vm' && !leftDrawerOpened ? null : item.title}{' '}
                        {item.soon ? (
                            <Chip size="small" label="Soon" color="primary" sx={{ ml: 1, fontSize: '9px', pl: 0.3, pr: 0.3 }} />
                        ) : null}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
        );
    };

    const getConnectorLine = () => {
        return (
            <Box
                sx={{
                    height: '1.5px',
                    width: '15px',
                    borderTop: `2px solid ${theme.palette.primary.main}`,
                    display: level > 1 ? 'block !important' : 'none',
                    position: 'absolute',
                    left: '32px',
                    bottom: '22px'
                }}
            />
        );
    };

    const itemProps = item.url === pathname ? {} : listItemProps;

    return (
        <>
            <ListItemButton
                {...itemProps}
                id={item.id}
                disabled={item.disabled || item?.soon}
                sx={getListItemStyles()}
                selected={isItemOpen}
                onClick={() => itemHandler(item.id)}
            >
                {getItemIcon()}
                <Box display="flex">
                    {getConnectorLine()}
                    {getItemText()}
                </Box>
                {item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    />
                )}
            </ListItemButton>
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
