// assets
import overview from '../assets/images/icons/overview.svg';
import vm from '../assets/images/icons/vm.svg';
import billing from '../assets/images/icons/billing-2.svg';
import helpdesk from '../assets/images/icons/helpdesk.svg';
import network from '../assets/images/icons/network.svg';
import ssh from '../assets/images/icons/ssh.svg';
import cloud from '../assets/images/icons/cloud.svg';

import overviewActive from '../assets/images/icons/overview-active.svg';
import vmActive from '../assets/images/icons/vm-active.svg';
import billingActive from '../assets/images/icons/billing-2-active.svg';
import helpdeskActive from '../assets/images/icons/helpdesk-active.svg';
import networkActive from '../assets/images/icons/network-active.svg';
import sshActive from '../assets/images/icons/ssh-active.svg';
import cloudActive from '../assets/images/icons/cloud-active.svg';

// ==============================|| MAIN MENU & DOCUMENTATION MENU ITEMS ||============================== //

const MainMenu = {
    id: 'main-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: overview,
            iconActive: overviewActive,
            breadcrumbs: false
        },
        {
            id: 'vm',
            title: 'Instances',
            type: 'item',
            url: '/instances',
            icon: vm,
            iconActive: vmActive,
            breadcrumbs: false
        },
        {
            id: 'network',
            title: 'Network',
            type: 'collapse',
            icon: network,
            iconActive: networkActive,
            children: [
                {
                    id: 'private_network',
                    parent_id: 'network',
                    title: 'Private Network',
                    type: 'item',
                    url: '/network/private_network',
                    breadcrumbs: false
                },
                {
                    id: 'public_network',
                    parent_id: 'network',
                    title: 'Public Network',
                    type: 'item',
                    url: '/network/public_network',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'storage',
            title: 'Storage',
            type: 'collapse',
            icon: cloud,
            iconActive: cloudActive,
            children: [
                {
                    id: 'block_storage',
                    parent_id: 'storage',
                    title: 'Block Storage',
                    type: 'item',
                    url: '/storage/block_storage',
                    breadcrumbs: false
                },
                {
                    id: 'object_storage',
                    parent_id: 'storage',
                    title: 'Object Storage',
                    type: 'item',
                    url: '/storage/object_storage',
                    breadcrumbs: false,
                    soon: true
                }
            ]
        },
        {
            id: 'authentication',
            title: 'Authentication',
            type: 'collapse',
            icon: ssh,
            iconActive: sshActive,
            children: [
                {
                    id: 'ssh',
                    parent_id: 'authentication',
                    title: 'SSH Keys',
                    type: 'item',
                    url: '/authentication/ssh',
                    breadcrumbs: false
                },
                {
                    id: 'api_token',
                    parent_id: 'authentication',
                    title: 'API Token',
                    type: 'item',
                    url: '/authentication/api_token',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'billing',
            title: 'Billing',
            type: 'collapse',
            icon: billing,
            iconActive: billingActive,
            billing: 'PRE',
            children: [
                {
                    id: 'top_up',
                    parent_id: 'billing',
                    title: 'Top Up',
                    type: 'item',
                    url: '/billing/top-up',
                    breadcrumbs: false,
                    billing: 'POST'
                },
                {
                    id: 'top_up_history',
                    parent_id: 'billing',
                    title: 'Top Up History',
                    type: 'item',
                    url: '/billing/top_up_history',
                    breadcrumbs: false,
                    billing: 'POST'
                },
                {
                    id: 'credit_history',
                    parent_id: 'billing',
                    title: 'Credit History',
                    type: 'item',
                    url: '/billing/credit_history',
                    breadcrumbs: false,
                    billing: 'POST'
                },
                {
                    id: 'report',
                    parent_id: 'billing',
                    title: 'Report',
                    type: 'item',
                    url: '/billing/report',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'billing-pre',
            title: 'Billing',
            type: 'collapse',
            icon: billing,
            iconActive: billingActive,
            billing: 'POST',
            children: [
                {
                    id: 'invoice',
                    parent_id: 'billing-pre',
                    title: 'Invoice',
                    type: 'item',
                    url: '/billing/invoice',
                    breadcrumbs: false
                },
                {
                    id: 'report',
                    parent_id: 'billing-pre',
                    title: 'Report',
                    type: 'item',
                    url: '/billing/report',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'helpdesk',
            title: 'Helpdesk',
            type: 'item',
            url: '/helpdesk',
            icon: helpdesk,
            iconActive: helpdeskActive,
            breadcrumbs: false
        }
    ]
};

export default MainMenu;
