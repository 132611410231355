import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { Currency } from 'helpers/formatCurrency';

import paymentIcon from '../../../../assets/images/icons/payment.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { MENU_OPEN, SET_MENU } from 'store/actions';
import { useTheme } from '@mui/styles';
import { dynamicBoxShadow } from 'helpers/cardShadow';

const Payment = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const storeBalance = useSelector((state) => state?.authReducer?.user_info?.userinfo?.balance);

    const [balance, setBalance] = useState('');

    const billingSystem = JSON.parse(localStorage.getItem('user_info'))?.userinfo?.billing;

    useEffect(() => {
        const balances = !storeBalance ? JSON.parse(localStorage.getItem('user_info'))?.userinfo?.balance : storeBalance;

        let intervalId;

        if (balance === undefined) {
            intervalId = setInterval(() => {
                setBalance(balances);
            }, 1000);
        }

        setBalance(balances);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [balance, storeBalance]);

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        navigate('/billing/top-up');
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    return (
        <Box
            onClick={() => itemHandler('billing')}
            sx={{
                background: '#ffff',
                borderRadius: '12px',
                cursor: 'pointer',
                boxShadow: dynamicBoxShadow,
                display: billingSystem === 'PRE' ? 'block' : 'none'
            }}
        >
            <Box alignItems={'center'} display="flex" style={{ padding: '5px 10px' }}>
                <Box mr={1} display={'flex'}>
                    <img style={{ paddingRight: '10px' }} src={paymentIcon} alt="" />
                    <Typography sx={{ position: 'relative', top: '4px' }} fontSize={'14px'} color={'#1D243C'} fontWeight={'bold'}>
                        Balance
                    </Typography>
                </Box>
                <Box sx={{ border: '1px solid #eeee', height: '20px' }}></Box>
                <Typography
                    ml={1}
                    fontSize={'14px'}
                    fontFamily={'Manrope Semibold'}
                    style={{ color: theme.palette.primary.main, position: 'relative', top: '1px' }}
                >
                    {balance === undefined || balance === '' ? (
                        <CircularProgress color="secondary" size={'10px'} />
                    ) : (
                        <Currency value={balance} currency="IDR" />
                    )}
                </Typography>
            </Box>
        </Box>
    );
};

export default Payment;
