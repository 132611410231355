import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import Joyride from 'react-joyride';

// assets
import maxcloud from '../../../assets/images/maxcloud-logo.svg';
import { IconMenu2 } from '@tabler/icons';
import Payment from './Payment';
import RewardBoxIcon from './Reward';
import { useSelector } from 'react-redux';
import { TooltipJoyride } from 'ui-component/tooltip/tooltipJoyride';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

// eslint-disable-next-line no-unused-vars
const Header = ({ handleLeftDrawerToggle, handleLogout }) => {
    const theme = useTheme();
    const user = useSelector((state) => state.authReducer.user_info.userinfo);
    const steps = useSelector((state) => state.alertReducer.steps);
    const showDemo = useSelector((state) => state?.authReducer?.user_info?.userinfo?.show_demo);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    },
                    [theme.breakpoints.up('sm')]: {
                        width: 228
                    }
                }}
            >
                <Box paddingTop={1} component="span" sx={{ display: { xs: 'none', md: 'none' }, flexGrow: 1 }}>
                    <img src={maxcloud} alt="" />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', display: { xs: 'block', lg: 'none', xl: 'none' } }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            '&:hover': {
                                background: theme.palette.primary.dark,
                                color: theme.palette.primary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {user && !user.is_phone_verified ? <RewardBoxIcon /> : null}

            <Box id="payment" sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Payment />
            </Box>
            <Box id="notif">
                <NotificationSection />
            </Box>
            <Box style={{ height: '20px', borderRight: '1px solid #EEEEEE', marginRight: '15px' }} />
            <Box id="profile">
                <ProfileSection handleLogout={handleLogout} />
            </Box>

            <Joyride
                tooltipComponent={TooltipJoyride}
                run={showDemo}
                continuous
                scrollToFirstStep
                showProgress
                showSkipButton={true}
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000
                    }
                }}
            />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    handleLogout: PropTypes.func
};

export default Header;
