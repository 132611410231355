import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, IconButton, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import LoadingScreen from 'ui-component/loadingScreen';
import AuthService from 'service/auth.service';
import ChatwootWidget from 'ui-component/chatwoot';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import { toast } from 'react-toastify';

// assets
import { IconChevronRight } from '@tabler/icons';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import AlertHeader from 'ui-component/alert/alertHeader';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    background: '#F5F7F9',
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: '60px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 70),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const user = JSON.parse(localStorage.getItem('user_info'));
    const url = window.location.pathname;
    const parts = url.split('/');
    const result = parts[1];

    const alertHeader = useSelector((state) => state.alertReducer.alertHeader);

    const navigate = useNavigate();

    const [loadingScreen, setLoadingScreen] = useState(false);

    const chatwoot_url = process.env.REACT_APP_CHATWOOT_BASE_URL;
    const chatwoot_token = process.env.REACT_APP_CHATWOOT_BASE_URL;

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();

    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd, result]);

    useEffect(() => {
        dispatch({ type: 'ALERT_HEADER', payload: user?.userinfo?.is_suspended });
        setLoadingScreen(true);
        if (!localStorage.getItem('user_info')) {
            navigate('/login');
        }
        setTimeout(() => {
            setLoadingScreen(false);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogout = async (setLogoutLoading) => {
        setLogoutLoading(true);
        AuthService.logout().then(() => {
            setTimeout(() => {
                setLogoutLoading(false);
                window.location.href = '/login';
            }, 1000);
        });
    };

    const handleStopImpersonate = () => {
        AuthService.stopImpersonate().then(
            (res) => {
                toast.success(res.message, { position: 'top-center', theme: 'colored' });
                AuthService.checkAuth().then((res) => {
                    localStorage.setItem('user_info', JSON.stringify(res.data));
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                });
            },
            (error) => {
                toast.error(error.response.data.message, { position: 'top-center', theme: 'colored' });
            }
        );
    };

    return (
        <>
            {chatwoot_url && chatwoot_token && (
                <span style={{ position: 'fixed', bottom: '30px', left: '28px', height: '50px', width: '50px' }} id="chatwidget">
                    <ChatwootWidget />
                </span>
            )}
            {user?.admin_id != null ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '25px',
                        background: '#adc6f4',
                        top: '0',
                        zIndex: 99999999,
                        position: 'fixed'
                    }}
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Tooltip title={'Stop impersonate'}>
                        <IconButton onClick={handleStopImpersonate} className="blink" size="small" color="error">
                            <StopCircleOutlinedIcon style={{ width: '18px', height: '18px' }} />
                        </IconButton>
                    </Tooltip>
                    <Typography fontSize="12px" fontFamily="Manrope Semibold" color={theme.palette.primary.main} textAlign={'center'}>
                        Impersonating
                    </Typography>
                </Box>
            ) : null}

            {loadingScreen ? (
                <LoadingScreen />
            ) : (
                <Box sx={{ display: 'flex', mt: 2 }}>
                    <CssBaseline />
                    {/* header */}
                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="inherit"
                        elevation={0}
                        sx={{
                            bgcolor: theme.palette.background.default,
                            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                            height: '60px',
                            borderBottom: '1px solid #EEEE',
                            marginTop: user?.admin_id != null ? '25px' : 0
                        }}
                    >
                        <Toolbar>
                            <Header handleLogout={handleLogout} handleLeftDrawerToggle={handleLeftDrawerToggle} />
                        </Toolbar>
                        <AlertHeader user={user} leftDrawerOpened={leftDrawerOpened} alertHeader={alertHeader} />
                    </AppBar>

                    {/* drawer */}
                    <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} handleLogout={handleLogout} />

                    {/* main content */}
                    <Main theme={theme} open={leftDrawerOpened}>
                        {/* breadcrumb */}
                        <Box
                            onClick={handleLeftDrawerToggle}
                            sx={{
                                background: 'white',
                                border: '1px solid #EEEE',
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                                boxShadow: '0px 4px 10px rgb(50 67 158 / 5%)',
                                left: leftDrawerOpened ? '249px' : '54px',
                                top: user?.admin_id != null ? '38px' : '46px',
                                zIndex: 1200,
                                position: 'fixed',
                                cursor: 'pointer',
                                '&:hover': {
                                    background: theme.palette.primary.main,
                                    color: theme.palette.primary.light
                                },
                                display: { xs: 'none', md: 'block' }
                            }}
                        >
                            {leftDrawerOpened ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightIcon />}
                        </Box>
                        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                        <Outlet />
                    </Main>
                </Box>
            )}
        </>
    );
};

export default MainLayout;
