import React from 'react';

class ChatwootWidget extends React.Component {
    componentDidMount() {
        // Add Chatwoot Settings
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: 'left', // This can be left or right
            locale: 'en', // Language to be set
            type: 'expanded_bubble' // [standard, expanded_bubble]
        };

        (function (d, t) {
            const BASE_URL = process.env.REACT_APP_CHATWOOT_BASE_URL;
            const g = d.createElement(t),
                s = d.getElementsByTagName(t)[0];
            g.src = BASE_URL + '/packs/js/sdk.js';
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);
            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
                    baseUrl: BASE_URL
                });
            };
        })(document, 'script');
    }

    render() {
        return null;
    }
}

export default ChatwootWidget;
