import { ALERT_HEADER, ERROR_MESSAGE, STEPS, SUCCESS_MESSAGE } from 'store/actions/alertAction';

export const initialState = {
    errorMessage: { type: '', message: '', name: '' },
    successMessage: { type: '', message: '', name: '' },
    alertHeader: false,
    steps: [
        {
            target: '#profile',
            title: 'Profile',
            content:
                'Explore our Profile section for easy account customization! Edit your profile, secure authentication, change phone numbers, and manage notifications hassle-free.',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            spotlightClicks: true
        },
        {
            target: '#notif',
            title: 'Notification',
            content:
                "Discover the magic of our Notification feature, where you'll receive personalized messages and timely alerts, ensuring you're always in the know!",
            disableOverlayClose: true
        },
        {
            target: '#payment',
            title: 'Balance',
            content:
                'Introducing our Balance feature! Effortlessly manage your finances and stay informed about your spending habits with our user-friendly tools.',
            disableOverlayClose: true
        },
        {
            target: '#dashboard',
            title: 'Dashboard',
            content:
                'Step into our Dashboard! Your go-to destination for accessing a comprehensive overview of your account, insights, and essential tools, all conveniently consolidated in one place.',
            disableOverlayClose: true
        },
        {
            target: '#vm',
            title: 'Instance',
            content:
                'Explore our Instance section, your gateway to managing your instances effortlessly. Here, you can view a list of all instances, create new ones, and delve into detailed information about each instance, complete with various features tailored to your needs.',
            disableOverlayClose: true
        },
        {
            target: '#network',
            title: 'Network',
            content:
                "In our Network section, you'll find everything you need to manage both private and public networks effectively. From configuring private networks for secure internal communication to optimizing public network settings for seamless external connectivity, we've got you covered.",

            disableOverlayClose: true
        },
        {
            target: '#storage',
            title: 'Storage',
            content:
                "In our Storage section, you'll find tools to manage block storage efficiently, with object storage on the horizon. Stay tuned for the arrival of object storage, which will further enhance your storage capabilities.",

            disableOverlayClose: true
        },
        {
            target: '#authentication',
            title: 'Authentication',
            content:
                'In our Authentication segment, where you can manage both SSH keys and API tokens. Secure your access with SSH keys for secure server connections, and generate API tokens to authenticate your API requests seamlessly.',

            disableOverlayClose: true
        },
        {
            target: '#billing',
            title: 'Billing',
            content:
                "In our Billing section, you'll find features such as top-up, top-up history, credit history, and reports. Manage your finances effectively with these tools designed to provide transparency and control over your billing activities.",

            disableOverlayClose: true
        },
        {
            target: '#helpdesk',
            title: 'Helpdesk',
            content:
                "In the Helpdesk section, you'll find tools to address your queries and seek assistance effectively. Access support resources, submit tickets, and track their status for swift resolution.",

            disableOverlayClose: true
        },
        {
            target: '#chatwidget',
            title: 'Chat Widget',
            content:
                'Here, our Chat Widget enables you to easily connect with our customer service team, ensuring quick and convenient communication.',

            disableOverlayClose: true
        }
    ]
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            };
        case SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload
            };
        case ALERT_HEADER:
            return {
                ...state,
                alertHeader: action.payload
            };
        case STEPS:
            return {
                ...state,
                steps: action.payload
            };
        default:
            return state;
    }
};

export default alertReducer;
