// project imports
import {
    BACKUP,
    DATA_CONSOLE,
    HOSTNAME,
    LOCATION,
    NETWORK,
    OPERATING_SYSTEM,
    OVERVIEW,
    PASSWORD,
    PRICING,
    ROLLBACK,
    SERVER_SIZE,
    DEDICATED_CPU,
    SSH_KEY,
    SUMMARY,
    TAB_MENU_DETAIL_VM,
    TIER,
    USERNAME,
    DELETE_VM_CHEKED,
    MIN_TEMPLATE
} from 'store/actions/vmActions';

// action - state management

export const initialState = {
    os: '',
    location: undefined,
    server_size: {
        total_cpu: 1,
        total_disk: 20,
        total_ram: 1
    },
    hostname: '',
    username: 'root',
    password: '',
    ssh_key: [],
    summary: '',
    dataOverview: '',
    pricing: { ram: 0, cpu: 0, disk: 0 },
    data_console: {},
    network: {
        use_public_ip: true,
        vswitch: null,
        vm_gateway_id: null,
        public_network: ''
    },
    tier: undefined,
    tabMenu: 'overview',
    rollback: false,
    dedicated_cpu: process.env.REACT_APP_DEDICATED_CPU == 'true',
    backup: true,
    delete_vm_checked: {
        public_network: true,
        block_storage: false
    },
    min_template: {
        min_cpu: 0,
        min_memory: 0,
        min_storage: 0
    }
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const vmReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPERATING_SYSTEM:
            return {
                ...state,
                os: action.payload
            };
        case LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case SERVER_SIZE:
            return {
                ...state,
                server_size: action.payload
            };
        case HOSTNAME:
            return {
                ...state,
                hostname: action.payload
            };
        case USERNAME:
            return {
                ...state,
                username: action.payload
            };
        case PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        case SSH_KEY:
            return {
                ...state,
                ssh_key: action.payload
            };
        case SUMMARY:
            return {
                ...state,
                summary: action.payload
            };
        case OVERVIEW:
            return {
                ...state,
                dataOverview: action.payload
            };
        case PRICING:
            return {
                ...state,
                pricing: action.payload
            };
        case DATA_CONSOLE:
            return {
                ...state,
                data_console: action.payload
            };
        case NETWORK:
            return {
                ...state,
                network: action.payload
            };
        case TIER:
            return {
                ...state,
                tier: action.payload
            };
        case TAB_MENU_DETAIL_VM:
            return {
                ...state,
                tabMenu: action.payload
            };
        case ROLLBACK:
            return {
                ...state,
                rollback: action.payload
            };
        case DEDICATED_CPU:
            return {
                ...state,
                dedicated_cpu: action.payload
            };
        case BACKUP:
            return {
                ...state,
                backup: action.payload
            };
        case DELETE_VM_CHEKED:
            return {
                ...state,
                delete_vm_checked: action.payload
            };
        case MIN_TEMPLATE:
            return {
                ...state,
                min_template: action.payload
            };
        default:
            return state;
    }
};

export default vmReducer;
