import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    CircularProgress,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// project imports
import MainCard from 'ui-component/cards/mainCard';
import Transitions from 'ui-component/extended/transitions';

// assets
import { deepOrange } from '@mui/material/colors';
import Payment from '../Payment';
import logoutIcon from '../../../../assets/images/icons/logout.svg';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = ({ handleLogout }) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const user = useSelector((state) => state.authReducer.user_info);
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [dataUser, setDataUser] = useState({});
    const [logoutLoading, setLogoutLoading] = useState(false);

    const convertedName = dataUser?.name?.charAt(0).toUpperCase();

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        const localStorageDataUser = JSON.parse(localStorage.getItem('user_info'))?.userinfo;

        setDataUser(localStorageDataUser);
    }, [user]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    border: 'none',
                    backgroundColor: '#EEF3FC',
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            bgcolor: deepOrange,
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <span style={{ color: 'white' }}>{convertedName}</span>
                    </Avatar>
                }
                label={
                    <Box alignItems={'center'} display="flex">
                        <Box ml={1}>
                            <KeyboardArrowDownIcon sx={{ color: 'black' }} />
                        </Box>
                    </Box>
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box ml={1} mr={1} sx={{ display: { xs: 'block', sm: 'none' } }} paddingTop={2}>
                                        <Payment />
                                    </Box>

                                    <Box sx={{ pl: 1, pr: 1 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 200,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                disabled={user?.admin_id != null ? true : false}
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 1}
                                                onClick={(event) => handleListItemClick(event, 1, '/profile')}
                                            >
                                                <ListItemIcon>
                                                    <Avatar
                                                        sx={{
                                                            ...theme.typography.mediumAvatar,
                                                            bgcolor: deepOrange,
                                                            cursor: 'pointer'
                                                        }}
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    >
                                                        <span style={{ color: 'white' }}>{convertedName}</span>
                                                    </Avatar>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Box ml={1}>
                                                            <Typography
                                                                fontFamily={'Manrope Medium'}
                                                                fontWeight="500"
                                                                style={{ fontSize: '14px', color: 'black' }}
                                                            >
                                                                {dataUser?.name}
                                                            </Typography>
                                                            <Typography style={{ fontSize: '12px', color: '#959595' }}>
                                                                {dataUser?.email}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </ListItemButton>

                                            {user && user?.admin_id != null ? null : (
                                                <>
                                                    <Box mt={1} mb={1}>
                                                        <Divider />
                                                    </Box>
                                                    <ListItemButton
                                                        disabled={logoutLoading}
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={() => handleLogout(setLogoutLoading)}
                                                    >
                                                        {logoutLoading ? (
                                                            <>
                                                                <ListItemIcon>
                                                                    <CircularProgress size={20} sx={{ color: '#000' }} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{ position: 'relative', top: '-1px' }}
                                                                            fontFamily={'Manrope Medium'}
                                                                            color="error"
                                                                            variant="body2"
                                                                        >
                                                                            Logging Out
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ListItemIcon>
                                                                    <img src={logoutIcon} alt="" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            sx={{ position: 'relative', top: '-1px' }}
                                                                            fontFamily={'Manrope Medium'}
                                                                            color="error"
                                                                            variant="body2"
                                                                        >
                                                                            Logout
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </ListItemButton>
                                                </>
                                            )}
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

ProfileSection.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileSection;
