import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import AuthService from 'service/auth.service';
import { useDispatch } from 'react-redux';

export const TooltipJoyride = ({ backProps, index, isLastStep, primaryProps, skipProps, step, tooltipProps }) => {
    const dispatch = useDispatch();
    const demoUpdate = async () => {
        try {
            await AuthService.demoUpdate();
            AuthService.checkAuth().then((res) => {
                dispatch({ type: 'USER_INFO', payload: res.data });
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box width={350} {...tooltipProps} sx={{ background: 'white', borderRadius: '12px' }} overflow="hidden">
            {step.title && (
                <Typography pt={2} pl={3} pr={3} fontSize={'16px'} fontFamily={'Manrope Semibold'} color="#1D243C" mb="3">
                    {step.title}
                </Typography>
            )}
            <Box pl={3} pr={3} pt={2} pb={2}>
                {step.content && (
                    <Typography fontSize={'14px'} color="#959595" fontFamily={'Manrope Medium'}>
                        {' '}
                        {step.content}
                    </Typography>
                )}
            </Box>
            <Box pl={3} pr={3} pt={1} pb={2}>
                <Box display={'flex'} justifyContent={!isLastStep ? 'space-between' : 'flex-end'} alignItems={'center'}>
                    {!isLastStep && (
                        <Box onClick={demoUpdate}>
                            <span {...skipProps} style={{ fontSize: '14px', cursor: 'pointer', fontFamily: 'Manrope Semibold' }}>
                                Skip
                            </span>
                        </Box>
                    )}
                    <Box gap={1} display={'flex'}>
                        {index > 0 && (
                            <Button sx={{ minWidth: '100px !important' }} color="primary" variant="outlined" {...backProps} size="sm">
                                Back
                            </Button>
                        )}

                        {!isLastStep ? (
                            <Button sx={{ minWidth: '100px !important' }} variant="contained" {...primaryProps} size="sm">
                                Next
                            </Button>
                        ) : (
                            <Box onClick={demoUpdate}>
                                <Button sx={{ minWidth: '100px !important' }} {...primaryProps} variant="contained" size="sm">
                                    Close
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

TooltipJoyride.propTypes = {
    backProps: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isLastStep: PropTypes.bool.isRequired,
    primaryProps: PropTypes.object.isRequired,
    skipProps: PropTypes.object.isRequired,
    step: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string
    }).isRequired,
    tooltipProps: PropTypes.object.isRequired
};
