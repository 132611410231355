// project imports

import { CONFIRM_CODE_VALUE, DATA_PROFILE, OPEN_MODAL_EMAIL } from 'store/actions/profileReducer';

// action - state management

export const initialState = {
    data_profile: {},
    confirm_code: '',
    open_modal_email: false
};

// ==============================|| DASHBOARD REDUCER ||============================== //

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_PROFILE:
            return {
                ...state,
                data_profile: action.payload
            };
        case CONFIRM_CODE_VALUE:
            return {
                ...state,
                confirm_code: action.payload
            };
        case OPEN_MODAL_EMAIL:
            return {
                ...state,
                open_modal_email: action.payload
            };

        default:
            return state;
    }
};

export default profileReducer;
