import { FormattedNumber } from 'react-intl';

// eslint-disable-next-line react/prop-types
export function Currency({ value, currency }) {
    return (
        <>
            <FormattedNumber value={value} currency={currency} style="currency" minimumFractionDigits={0} maximumFractionDigits={0} />
        </>
    );
}
