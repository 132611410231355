import { Dialog, DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import closeModal from '../../assets/images/icons/close_modal.png';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const MainModal = ({ handleCloseModal, openModal, content, hideCloseIcon, maxWidth = '500px' }) => {
    return (
        <Dialog onClose={handleCloseModal} open={openModal}>
            <DialogContent sx={{ maxWidth: maxWidth }}>
                <Box
                    display={hideCloseIcon ? 'none' : 'block'}
                    style={{ position: 'absolute', right: '20px', top: '20px', cursor: 'pointer' }}
                    onClick={handleCloseModal}
                >
                    <img src={closeModal} alt="" />
                </Box>
                <Box pt={3}>{content}</Box>
            </DialogContent>
        </Dialog>
    );
};
MainModal.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    content: PropTypes.node.isRequired,
    hideCloseIcon: PropTypes.bool,
    maxWidth: PropTypes.string
};
export default MainModal;
